.MobileActionsList {
  width: 100%;

  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-end;
  color:white;
  transition: opacity 1s;
}

.MobileActionsList.hidden {
  opacity: 0;
}
