.DescriptionComponent {
  align-self: flex-start;
}

.DescriptionComponent p {
  margin: 15px 0px 0px 0px;
  color: var(--text-dark-grey);
  text-align: left;
  font-size: 20px;
}
.ImageComponent {
  max-width:80%
}
@media (max-height: 1000px) {
  .ImageComponent {
    max-width:60%
  }
  .DescriptionComponent p {
    font-size: 18px;
  }
  
}
