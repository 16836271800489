.ActionsList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 10px;
  transition: opacity 1s;
}

.ActionsList.hidden {
  opacity: 0;
}

.ActionsListHeader {
  min-height: 50px;
  width: 100%;
  background-color: white;
  color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ActionsListHeader span {
  margin: 10px 25px;
}
