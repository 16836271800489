.ContactBoxWrapper {
}

.ContactBox {
  margin: 10px 10px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  white-space: pre-line;
  padding: 1rem;
  transition: opacity 1s;
}

.ContactBox.hidden {
  opacity: 0;
}
.ContactBox span {
  color: rgba(0, 123, 133, 1);
}

.ContactBoxIcon {
  margin-right: 15px;
}
.DetailsSpan{
  min-width:200px;
  font-size: 1rem;
}
.DetailsSpan a {
  color: rgba(0, 123, 133, 1);
}

.ContactDetailsTitle{
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  min-width:200px;
  text-align: center;

}