.LastUtterance {
  width: 100%;
  max-width: calc(600px * var(--spacebar-scaling-factor));
  margin: 0 auto;
  min-height: calc(60px * var(--spacebar-scaling-factor));
  background-color: var(--primary-color-opaque);
  display: flex;
  align-items: center;
  transition: opacity 1s;
}

.LastUtterance.hidden {
  opacity: 0;
}

.LastUtterance img {
  max-width: calc(30px * var(--spacebar-scaling-factor));
  margin: 0px 3% 1% 3%;
}

.LastUtterance span {
  color: white;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .LastUtterance {
    width: 80%;
    height: 60px;
  }
}
