.SuggestedQuestion {
  min-height: 50px;
  width: 100%;
  margin-top: 3px;
  background-color: var(--primary-color-opaque);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.SuggestedQuestion span {
  margin: 10px 25px;
}

.SuggestedQuestion.pulse {
  animation: pulse 2s 1.3s ease-in-out infinite forwards;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
