.TitleComponent {
  align-self: flex-start;
}

.TitleComponent p {
  color: var(--primary-color);
  font-weight:500;
  text-align: left;
  font-size: 45px;
  margin: 0.5rem 0;
  line-height: 1;
}
