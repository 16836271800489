.MobileSuggestedQuestion {
  background: var(--mobile-actions-background);
  box-shadow: 0 2px 7px 2px var(--mobile-actions-shadow);
  border-radius: 22.5px;
  margin: 5px 10px;
  padding: 10px 0px;
  color: white;
  text-align: right;
}

.MobileSuggestedQuestion.pulse {
  background: var(--mobile-actions-background-animation);
  animation: pulse 2s 1.3s ease-in-out infinite forwards;
}

@keyframes pulse {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.4;
  }
}

.MobileSuggestedQuestion span {
  font-size: 16px;
  margin: 0px 15px;
}
@media (max-width: 400px) {
  .MobileSuggestedQuestion span {
    font-size: 14px;
  }
}

.MobileSuggestedQuestion:active {
  background: var(--mobile-actions-background-active);
}
