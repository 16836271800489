body {
  margin: 0;
  font-weight: 300;
  font-style: normal;
  font-family: 'Campton Light DEMO', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--main-font-size);
  /* Makes sure that we don't trigger text selection on iOS Mobile */
  -webkit-touch-callout: none !important;
}
a:visited {
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --z-index-digitalhuman: -999;
  --z-index-loadingcomponent: -998;
  --z-index-modal: 999;
  --z-index-mobileburger: 998;
  --z-index-mobilemenu: 997;
}

* {
  box-sizing: border-box;
}
