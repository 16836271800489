.Modal {
  max-width: 1200px;
  width: auto;
  width:60%
}


@media (max-width: 1000px) {
  .Modal{
    width:90%;
  }
}

.CloseContainer {
  width: 100%;
}
.CloseIcon {
  float: right;
  width: 14px;
  padding-bottom: 10px;
  cursor: pointer;
}

.plyr {
  max-height: 39%;
  max-height: 60vh;
}
#youtubeIFrame{
  height:80%;
  width: 100%;
}