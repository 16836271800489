.PermissionModalTitle {
  font-weight: bold;
  text-align: left;
  margin: 4px 0px;
}

.PermissionModalBody {
  text-align: left;
  margin-bottom: 20px;
}

.PermissionModal.Modal {
  max-width:600px;
}

.PermissionModal button {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 32px;
  padding-right: 32px;
  margin-bottom: 8px;

  align-self: center;

  box-sizing: border-box;
  text-align: center;
  color: white;
  min-height: 2rem;
  min-width: 15rem;
  font-size: 1rem;
  border: 0px solid;
  border-radius: 0px;
  font-weight: 500;
  display: inline-block;

  cursor: pointer;
}

.PermissionModalButtonClose {
  background-color: var(--secondary-color);
}

.PermissionModalButtonClose:hover {
  background-color: var(--secondary-color-lighter);
}

.PermissionModalButtonGrant {
  background-color: var(--primary-color);
}

.PermissionModalButtonGrant:hover {
  background-color: var(--primary-color-lighter);
}
