.SpaceBar {
  width: 100%;
  max-width: calc(600px * var(--spacebar-scaling-factor));
  min-height: calc(60px * var(--spacebar-scaling-factor));
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid var(--primary-color-opaque);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: opacity 1s;
}

.SpaceBar.hidden {
  opacity: 0;
}

.SpaceBar span {
  color: var(--primary-color);
  font-size: 16px;
  text-align: center;
}

@media (max-width: 767px) {
  .SpaceBar {
    width: 80%;
    min-height: 60px;
  }

  .SpaceBar span {
    font-size: 14px;
  }
}
