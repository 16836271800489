:root {
  --main-font-size: 16px;
  --spacebar-scaling-factor: 1;

  --primary-color: rgba(0, 123, 133, 1);
  --primary-color-opaque: rgba(0, 123, 133, 0.8);
  --primary-color-lighter: rgba(10, 160, 170, 1);
  --primary-color-hex: #007b85;

  --secondary-color: rgba(169, 0, 96, 1);
  --secondary-color-opaque: rgba(169, 0, 96, 0.8);
  --secondary-color-lighter: rgba(195, 65, 140, 1);
  --secondary-color-hex: #a90060;

  --text-dark-grey: rgba(51, 51, 51, 1);

  --background-color-modal: rgba(255, 255, 255, 1);
  --spinner-color: rgba(255, 255, 255, 1);

  /* First one must be hex, to make the SVG change color as well. */
  --mobile-bars-background: var(--primary-color-hex);
  --mobile-bars-top-border: var(--primary-color);
  --mobile-bars-color:  rgba(255, 255, 255, 1);

  --mobile-record-start-color: rgba(255, 255, 255, 1);
  --mobile-record-tap-color: rgba(220, 220, 220, 1);
  --mobile-record-stop-color: rgba(255, 0, 0, 1);

  --mobile-actions-background: var(--secondary-color);
  --mobile-actions-background-animation: rgb(97, 0, 55);
  --mobile-actions-background-active: var(--secondary-color-lighter);
  --mobile-actions-shadow: rgba(33, 33, 33, 0.5);

  /* Below must be hex, to make the SVGs change color. */
  --mobile-actions-link-icon: var(--primary-color-hex);
}
